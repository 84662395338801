import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter, useGridApiRef } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdCheckCircle, MdDelete } from 'react-icons/md';
import { TextField, MenuItem, Tooltip, Skeleton, Tabs, Tab } from '@mui/material';
import OutlinedDiv from '../layout/OutlinedDiv';
import PreferredCustomerPayments from './preferred-customer-payments';
import PreferredCustomerAccountChanges from './preferred-customer-account-changes';
import PreferredCustomerSiteUsage from './preferred-customer-site-usage';
import { bootstrap } from '../../app';
import useWindowDimensions from '../../services/useWindowDimensions';
import PreferredCustomerAccountInfo from './preferred-customer-account-info';
import {DateTime} from 'luxon';
import { blurOnEnterKey } from '../../services/utilities';

const PreferredCustomers = (props) => {
    const [preferredCustomers, setPreferredCustomers] = useState([]);
    const [preferredCustomerIdToActivate, setPreferredCustomerIdToActivate] = useState(null);
    const [preferredCustomerToActivate, setPreferredCustomerToActivate] = useState(null);
    const [approvalNote, setApprovalNote] = useState('');
    const [preferredCustomerIdToEdit, setPreferredCustomerIdToEdit] = useState(null);
    const [updatedPreferredCustomerForList, setUpdatedPreferredCustomerForList] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [regions, setRegions] = useState([]);
    const [primaryRegionId, setPrimaryRegionId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    let { paramPreferredCustomer } = useParams();
    const { loadedSizeIsMobile, width } = useWindowDimensions();
    const approvalModal = useRef();
    const apiRef = useGridApiRef();
    const navigate = useNavigate();

    const columns = [
        { field: 'name', headerName: 'Name', flex: 150 },
        { field: 'companyName', headerName: 'Company', flex: 150 },
        { field: 'contact', headerName: 'Contact', flex: 200, renderCell: function (t) { return <p className="mb-0" dangerouslySetInnerHTML={{ __html: t.value }}></p> } },
        { field: 'signUpDate', type: 'dateTime', headerName: 'Sign Up Date', flex: 175, renderCell: function (t) {
                if (t.row.status.includes('REQUESTING ACTIVATION') || t.row.status === 'NEW/PENDING') {
                    return <p className="mb-0" dangerouslySetInnerHTML={{
                        __html: `${t.value.toLocaleDateString("en-US")}<br />Days Pending: ${Math.floor(DateTime.now().diff(DateTime.fromISO(t.row.mostRecentStatusChangeDate), ['days',]).days)}`
                    }}></p>
                }
                else {
                     return t.value.toLocaleDateString("en-US")
                }
            }
        },
        { field: 'profession', headerName: 'Profession', flex: 100 },
        { field: 'referredBy', headerName: 'Referred By', flex: 150 },
        { field: 'status', headerName: 'Status', flex: 200, renderCell: function (t) {
                if (t.row.mostRecentChangeNote) {
                    return <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${t.value}<br />${(t.row.mostRecentChangeNote)}` }}></p>;
                }
                else {
                    return t.value;
                }
            }
        },
        {
            field: 'actions',
            type: 'actions',
            renderHeader: function (t) { return loadedSizeIsMobile?<MdEdit/> : <span>Actions</span >},
            width: loadedSizeIsMobile ? 50 : 100,
            cellClassName: 'actions',
            getActions: (props) => {
                var actions = [
                    <GridActionsCellItem
                        icon={<MdEdit title="Edit"/>}
                        label="Edit"
                        showInMenu={loadedSizeIsMobile}
                        onClick={() => { setPreferredCustomerIdToEdit(props.id); }}
                        color="inherit" />
                ];
                if (props.row.status.includes('REQUESTING ACTIVATION') || props.row.status.includes('NEW/PENDING')) {
                    actions.push(<GridActionsCellItem
                        icon={<MdCheckCircle title="Activate" />}
                        label="Activate"
                        showInMenu={loadedSizeIsMobile}
                        onClick={() => { setPreferredCustomerIdToActivate(props.id); }}
                        color="inherit"
                       />);

                    actions.push(<GridActionsCellItem
                        icon={<MdDelete title="Decline" />}
                        label="Decline"
                        showInMenu={loadedSizeIsMobile}
                        onClick={() => { declinePreferredCustomer(props.id); }}
                        color="inherit" />);
                }
                return actions;
            }
        }
    ];

    const formatDataForGrid = (a) => {
        return {
            ...a,
            id: a.preferredCustomerId,
            name: a.firstName + ' ' + a.lastName,
            companyName: a.businessName,
            contact: a.mobilePhone + `<br />${a.email}`,
            signUpDate: new Date(a.signUpDate),
            statusId: a.preferredCustomerStatusId,
            status: a.preferredCustomerStatus.statusDescription
        };
    }

    useEffect(() => {
        if (paramPreferredCustomer) {
            const getQueryString = async () => {
                apiAdmin.get(`/get-encoded-query-string-parameter?queryString=${paramPreferredCustomer}`)
                    .then((response) => {
                        if (response.data.preferredCustomerId) {
                            if (response.data.directFromEmail) {
                                setPreferredCustomerIdToActivate(response.data.preferredCustomerId);
                            } else {
                                setPreferredCustomerIdToEdit(response.data.preferredCustomerId);
                            }
                        }
                    });
            };
            getQueryString();
        }
    }, [paramPreferredCustomer]);

    useEffect(() => {
        const getRegions = async () => {
            apiAdmin.get(`/regions`)
                .then((response) => {
                    setRegions(response.data);

                });
        };        
        getRegions();

        const getStatuses = async () => {
            apiAdmin.get(`/preferred-customer-statuses`)
                .then((response) => {
                    setStatuses(response.data);
                });
        };

        getStatuses();

        const getUserRegion = async () => {
            apiAdmin.get(`/preferred-customer/user-region`)
                .then((response) => {
                    setPrimaryRegionId(response.data.regionId);
                });
        };

        getUserRegion();

    }, []);


    useEffect(() => {
        const getPreferredCustomers = async () => {
            apiAdmin.get(`/preferred-customers?primaryRegionId=` + primaryRegionId)
                .then((response) => {
                    setPreferredCustomers(response.data.map((a) => formatDataForGrid(a)));
                    setLoading(false);
                });
        };

        if (!primaryRegionId) return;
        setLoading(true);
        getPreferredCustomers();
    }, [primaryRegionId]);

    useEffect(() => {
        if (!apiRef || !apiRef.current || !submitted || !updatedPreferredCustomerForList) return;
        var updatedRow = formatDataForGrid(updatedPreferredCustomerForList);
        apiRef.current.updateRows([updatedRow]);
        window.scrollTo(0, 0);
        setUpdatedPreferredCustomerForList(null);
    }, [apiRef, submitted, updatedPreferredCustomerForList]);

    useEffect(() => {
        if (!preferredCustomerIdToActivate || loading) return;
        const getPreferredCustomer = async () => {
            apiAdmin.get(`/preferred-customer?preferredCustomerId=${preferredCustomerIdToActivate}`)
                .then((response) => {
                    if (response.data.preferredCustomerStatusId === 1) {
                        setPreferredCustomerIdToActivate(null);
                        setError("This preferred customer has already been activated.");
                        return;
                    }
                    var modal = new bootstrap.Modal(approvalModal.current, {});
                    modal.show();
                    setPreferredCustomerToActivate(response.data);
                });
        };
        getPreferredCustomer();
    }, [preferredCustomerIdToActivate, loading]);

    const activatePreferredCustomer = () => {
        const activate = async () => {
            await apiAdmin.post(`/activate-preferred-customer?preferredCustomerId=` + preferredCustomerIdToActivate, approvalNote)
                .then((response) => {
                    if (response.data) {
                        setUpdatedPreferredCustomerForList(response.data);
                        setSubmitted(true);
                        setApprovalNote('');
                        setPreferredCustomerIdToActivate(null);
                        setPreferredCustomerToActivate(null);
                        setLoading(false);
                    } else {
                        setError("There was an error activating the preferred customer. Please try again.");
                    }
                });
        };
        setLoading(true);
        activate();
    };
    const declinePreferredCustomer = id => {
        const decline = async () => {
            await apiAdmin.post(`/decline-preferred-customer?preferredCustomerId=` + id)
                .then((response) => {
                    if (response.data) {
                        setUpdatedPreferredCustomerForList(response.data);
                        setSubmitted(true);
                        setLoading(false);
                    } else {
                        setError("There was an error declining the preferred customer. Please try again.");
                    }
                });
        };
        setLoading(true);
        decline();
    };

    function EditToolbar(props) {
        return (
            <GridToolbarContainer className="py-2">
                <div className="d-flex justify-content-between w-100">
                    <div className="col px-1">
                        <GridToolbarQuickFilter {...props.quickFilterProps} className="w-100" />
                    </div>
                    <div className="col d-none d-md-flex px-1">
                        <OutlinedDiv label="Status Key">
                            <div className="row">
                                {statuses.filter(f => !f.statusDescription.includes('REQUESTING ACTIVATION')).map(s => <div className="col" key={"statusKey" + s.preferredCustomerStatusId}><Tooltip title={s.statusDescription === 'NEW/PENDING' ? 'PENDING (needs approval)' : s.statusDescription} placement="top"><div style={{ width: '23px', height: '23px' }} className={"border preferred-customer-status-" + s.preferredCustomerStatusId}></div></Tooltip></div>
                                )}
                            </div>
                        </OutlinedDiv>
                    </div>
                    <div className="col px-1">
                        {regions.length > 0 ?
                            <TextField
                                select
                                label="Region"
                                value={primaryRegionId}
                                className="w-100"
                                onChange={(e) => setPrimaryRegionId(e.target.value)}
                                disabled={loading}
                            >
                                {regions.map((r) => (
                                    <MenuItem key={"region_" + r.regionId} value={r.regionId}>
                                        {r.regionName}
                                    </MenuItem>
                                ))}
                            </TextField>
                            : <Skeleton variant="rectangular" className="w-100" />}
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }
    useEffect(() => {
        setSelectedTab(0);
    }, [preferredCustomerIdToEdit]);

    return (
        <div>
            <Helmet>
                <title>Preferred Customers</title>
            </Helmet>
            <h1>Preferred Customers</h1>

            {preferredCustomerIdToEdit && <>
                <Tabs value={selectedTab} onChange={(e, newVal) => { setSelectedTab(newVal); }} scrollButtons={true} allowScrollButtonsMobile={true} variant="scrollable" aria-label="Preferred Customer Edit Tabs">
                    <Tab label="Account Information" />
                    <Tab label="Payments" />
                    <Tab label="Change Summary" />
                    <Tab label="Site Usage" />
                </Tabs>
                <div className={selectedTab === 0 ? "p-3 border" : "d-none"}><PreferredCustomerAccountInfo preferredCustomerIdToEdit={preferredCustomerIdToEdit} setPreferredCustomerIdToEdit={setPreferredCustomerIdToEdit} setError={setError} setSubmitted={setSubmitted} regions={regions} statuses={statuses} setUpdatedPreferredCustomerForList={setUpdatedPreferredCustomerForList} /></div>
                {selectedTab === 1 && <div className="p-3 border"><PreferredCustomerPayments preferredCustomerId={preferredCustomerIdToEdit} setPreferredCustomerIdToEdit={setPreferredCustomerIdToEdit} /></div>}
                {selectedTab === 2 && <div className="p-3 border"><PreferredCustomerAccountChanges preferredCustomerId={preferredCustomerIdToEdit} statuses={statuses} setPreferredCustomerIdToEdit={setPreferredCustomerIdToEdit} /></div>}
                {selectedTab === 3 && <div className="p-3 border"><PreferredCustomerSiteUsage preferredCustomerId={preferredCustomerIdToEdit} setPreferredCustomerIdToEdit={setPreferredCustomerIdToEdit} /></div>}
            </>}
            <>
                {!preferredCustomerIdToEdit && submitted && <><div className="alert alert-success alert-dismissible" role="alert">Preferred Customer saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {!preferredCustomerIdToEdit && error && <div className="alert alert-danger alert-dismissible" role="alert">{error}<button type="button" className="btn-close" onClick={() => { setError(false); navigate('/preferred-customers'); }} aria-label="Close"></button></div>}
                <DataGridPro
                    sx={{ display: ((preferredCustomerIdToEdit) ? 'none' : '') }}
                        rows={preferredCustomers}
                        columns={columns.map((column) => {
                            if (column.field === 'referredBy') {
                                return {
                                    ...column,
                                    getApplyQuickFilterFn: undefined,
                                };
                            }
                            return column;
                        })}
                        loading={loading}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                            toolbar: {
                                quickFilterProps: {
                                    variant: "outlined",
                                    onKeyDown: blurOnEnterKey
                                }
                            }
                        }}
                        autoHeight
                        rowHeight={70}
                        disableRowSelectionOnClick
                        alignItems="center"
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    companyName: width > 1000,
                                    phone: width > 750,
                                    profession: width > 750,
                                    contact: !loadedSizeIsMobile,
                                    referredBy: !loadedSizeIsMobile,
                                    signUpDate: !loadedSizeIsMobile
                                }
                            },
                        }}
                        pagination
                        apiRef={apiRef}
                        getRowClassName={(params) => `preferred-customer-status-${params.row.statusId}`}
                    />
            </>
            <div className="modal shown" tabIndex="-1" id="activateModal" ref={approvalModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Activate Preferred Customer?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {preferredCustomerToActivate && <p>Are you sure you want to activate {preferredCustomerToActivate.firstName} {preferredCustomerToActivate.lastName}?</p>}
                            <TextField label="Notes" value={approvalNote} onChange={(e) => setApprovalNote(e.target.value)} className="w-100"></TextField>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setPreferredCustomerIdToActivate(null); setPreferredCustomerToActivate(null); setApprovalNote(''); }}>No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => activatePreferredCustomer()}>Yes, Activate</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreferredCustomers;

